import { Container, Link, Typography } from "@mui/material";
import { getInstagramPosts } from "@cm-websites/common/dist/instagram-feed/api";
import { GetStaticProps } from "next";
import { InstagramFeed, InstagramPost } from "@cm-websites/common";
import { NavigationAndFooterLayout } from "../layouts";
import { NextPageWithLayout } from "@cm-websites/common";
import { NextSeo } from "next-seo";
import React from "react";

type NotFound404Props = { instagramPosts: InstagramPost[] };

export const NotFound404Page: NextPageWithLayout<NotFound404Props> = ({ instagramPosts = [] }) => (
  <React.Fragment>
    <NextSeo title="404 | Culinario Mortale®" noindex nofollow />
    <Container
      maxWidth="md"
      sx={{ pt: { xs: 12, sm: 14, lg: 16 }, pb: { xs: 8, sm: 10, lg: 14, xl: 16 } }}
    >
      <Typography variant="h1" sx={{ pb: 6 }}>
        Entschuldigung
      </Typography>
      <Typography variant="body1" paragraph>
        Hier ist etwas gründlich schief gelaufen. Wir konnten die gesuchte Seite leider nicht finden.
        Bitte verwende die Navigation, um zu einer anderen Seite zu gelangen.
      </Typography>
      <Typography variant="body1" paragraph>
        Du kannst uns bei Fragen auch jederzeit unter{" "}
        <Link href="mailto:support@culinario-mortale.de">support@culinario-mortale.de</Link> erreichen.
      </Typography>
    </Container>
    <InstagramFeed posts={instagramPosts} />
  </React.Fragment>
);

NotFound404Page.getLayout = (page) => <NavigationAndFooterLayout>{page}</NavigationAndFooterLayout>;

export default NotFound404Page;

export const getStaticProps: GetStaticProps = async () => {
  const instagramPosts = await getInstagramPosts();

  return {
    props: { instagramPosts },
    revalidate: 259200,
  };
};
